import React from 'react';
import "./style.scss";

const Content = ({ children }) => (
  <main className="container">
    {/* <div className="content-wrapper"> */}
        {children}
    {/* </div> */}
  </main>
);

export default Content;
