import React from 'react';

import './style.scss';
import Helmet from './helmet';
import Header from './header';
import Footer from './footer';
import Content from './content';
import WiseWords from './wisewords';


require("typeface-lato");
require("typeface-playfair-display");


const Layout = (props) => {
	let className = props.className || "";
	return (
	<>
		<Helmet />
		<Header />
		<Content>
			<WiseWords />
			<div className={`content ${className}`}>
				{props.children}
			</div>
		</Content>
		<Footer />
	</>
)};

export default Layout;
