import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

export default (props) => {

	let pageTitle = props.title;

	return (
		<StaticQuery
			query={graphql`
				query helmetQuery {
					site {
						siteMetadata {
							title
							author
							imageUrl
							description
							keywords
						}
					}
				}
			`}
			render={data => {
				let pageTitleFull = data.site.siteMetadata.title;
				if(pageTitle) pageTitleFull = [pageTitle, " – ", pageTitleFull].join("");  
				return (
				<Helmet>
					<meta
						name="viewport"
						content="width=device-width, initial-scale=1, maximum-scale=5"
					/>
					<meta name="description" content={data.site.siteMetadata.description} />
					<meta name="keywords" content={data.site.siteMetadata.keywords} />
					<title>{pageTitleFull}</title>
					<html lang="en" />
					{/* Google / Search Engine Meta Tags */}
					<meta itemprop="name" content={data.site.siteMetadata.author} /> />
					<meta
						itemprop="description"
						content={data.site.siteMetadata.description}
					/>
					<meta itemprop="image" content={data.site.siteMetadata.imageUrl} /> />
				</Helmet>
			) } }
		/>
	)
};
