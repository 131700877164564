import React from 'react';
import {Link} from 'gatsby';
import Brand from './brand';

class Navbar extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			burgerMenu: ''
		}
	}

	toggleBurgerMenu = (event) => {
		event.preventDefault();
		if (this.state.burgerMenu === "") this.setState({ burgerMenu: "is-active"});
		else this.setState({ burgerMenu: "" });
	}

	render() { 
		return (
        <nav className="navbar has-shadow-default" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item logo-container">
              <Brand/>
            </Link>

            <button className={`navbar-burger burger ${this.state.burgerMenu}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={this.toggleBurgerMenu}>
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </button>
          </div>

          <div className={`navbar-menu ${this.state.burgerMenu}`}>
            <div className="navbar-start">
              <Link className="navbar-item" to="/">
                Start
              </Link>
              <Link className="navbar-item" to="/maltherapie/">
                Maltherapie
              </Link>
              <Link className="navbar-item" to="/maltherapie-mit-kindern/">
                Maltherapie<br/>mit Kindern
              </Link>
              <Link className="navbar-item" to="/krisenbewaeltigung/">
                Krisen<br/>bewältigung
              </Link>
              <Link className="navbar-item" to="/supervision/">
                Supervision
              </Link>
              <Link className="navbar-item" to="/ueber/">
                Über Mich
              </Link>
              <Link className="navbar-item" to="/kontakt/">
                Kontakt
              </Link>
            </div>
          </div>
        </nav>
    )
	}
}

export default Navbar;
