import React from 'react';
import './style.scss';

import headerBackground from '../images/aquarell.jpg';
import Navbar from './navbar';

const Header = ({ data, siteTitle }) => (
  <>
    <Navbar />
    <div 
      className="header-background" 
      style={{backgroundImage: `url(${headerBackground})`}}>
    </div>
  </>
);

export default Header;
