import React from 'react';
import {Link} from 'gatsby';

const Footer = () => (
		<footer className="footer container">
				<div className="columns">


					<div className="column ">
						<p className="">
							Praxis für kreative Lebensgestaltung
							<br/>
							Wolfgang Engelhardt
						</p>
					</div>

					<div className="column has-text-centered ">
						<p><i>Liebe verleiht der Seele den Mut aus seinem Versteck hervor zu treten.</i></p>
					</div>

					<div className="column footer-links  has-text-right ">
						<div>
							<Link to="/kontakt/">Kontakt</Link>
							<br/>
							<Link to="/impressum/">Impressum</Link>
							<br/>
							<Link to="/datenschutzerklaerung/">Datenschutz</Link>
						</div>
					</div>
				</div>
		</footer>
);

export default Footer;
