import React from 'react';
// import pageLogo from '../images/logo.png';
import pageLogo from '../images/logo-schatten.png';

const Brand = () => (
    <>
        <img src={pageLogo}  className="logo" alt="Wolfgang Engelhardt Logo"/>
        <div className="logo-brand">
            <strong>Praxis für <br /> kreative Lebensgestaltung</strong>
        </div>
    </>
)

export default Brand;